/*  -------------------------------------------------------
    ------------------->>> VARIABLES <<<----------------------
    -------------------------------------------------------  */

//COLORS
$red: #a11;
$white: #fff;
$gray: #7a7a7a;
$black: #222;
$blackFooter: #2b2b2b;
$gradientRed: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0, rgba(255, 255, 255, 0.7) 8%, rgba(255, 255, 255, 0) 100%);
$bg_grey: #E8E8E8;
$input_grey: #ccc;
$yellow: #e2be0e;
//USE OF COLORS

//FONTS
$fontPath: '../../fonts/';

//IMAGES
$imgPath: '../../img/';



//BREAK POINTS
$break-small: 576px;
$break-medium: 768px;
$break-large: 992px;
$order: 1103px;
$break-extra-large: 1200px;
$break-super-large: 1500px;
$break-end: 1900px;