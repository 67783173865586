.input-group-btn-vertical {
	@include flex(center, center, column);
	.btn-touchspin {
		@include flex(center, center);
	}
}
body {
	.product-price {
	    color: $red;
	}
	.bootstrap-touchspin {
		@include flex(center, center);
	}
} 
