@mixin flex($justifyContent, $alignItems, $flexDirection: row) {
    display: flex;
    @if $flexDirection != row {
        flex-direction: $flexDirection;
    }
    align-items: $alignItems;
    justify-content: $justifyContent;
}

@mixin font ($family,$size,$align: left) {
    font-family: $family;
    font-size: $size;
    @if $align != left {
        text-align: $align;
    }
}

// Breakpoints OnePage
@mixin respond($breakpoint) {
    @if $breakpoint == phone-xs {
        @media screen and (max-width: 500px){
            @content
        };
    }
    @if $breakpoint == phone {
        @media screen and (max-width: 600px){
            @content
        };
    }
    @if $breakpoint == tab-phone {
        @media screen and (max-width: 690px){
            @content
        };
    }
    @if $breakpoint == tab-sm {
        @media screen and (max-width: 767px){
            @content
        };
    }
    @if $breakpoint == tab-header {
        @media screen and (max-height: 870px){
            @content
        };
    }
    @if $breakpoint == tab-port {
        @media screen and (max-width: 991px){ 
            @content
        };
    }
    @if $breakpoint == tab-apple {
        @media screen and (max-width: 1024px){ 
            @content
        };
    }
    @if $breakpoint == tab-land {
        @media screen and (max-width: 1199px){ 
            @content
        };
    }
    @if $breakpoint == menu-small {
        @media screen and (max-width: 1299px){ 
            @content
        };
    }
    @if $breakpoint == laptop {
        @media screen and (max-width: 1440px){  
            @content
        };
    }
    @if $breakpoint == laptop-large {
        @media screen and (max-width: 1600px){  
            @content
        };
    }
    @if $breakpoint == large {
        @media screen and (max-width: 1750px){  
            @content
        };
    }
    @if $breakpoint == fhd {
        @media screen and (max-width: 1920px){  
            @content
        };
    }
}



