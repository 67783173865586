.row.glasstype {
	@include flex(center, flex-start);
}
#order-step .text input[type=number] {
    width: 70px;
}
.glassform {
	img {
		max-width: 115px !important;
		max-height: 115px;
    	object-fit: contain;
	}
}
#mesure {
	max-width: 386px;
	margin: 0 auto;
}
.error {
	margin: 0 0 10px 0;
    padding: 10px;
    border: 1px solid $red;
    font-size: 13px;
    background: #ffcccc;
}
.success {
	margin:0 0 10px 0;
	padding:10px;
	border:1px solid #339900;
	font-size:13px;
	background: #d3ffab;
}
.warning {
	margin:0 0 10px 0;
	padding:10px;
	border:1px solid #e6db55;
	font-size:13px;
	background:#ffffe0
}
.estimate-price {
	padding-left: 0 !important;
	white-space: nowrap;
}