body {
	#footer {
		padding-top: 0;
		.footer-container {
		    padding-top: 0;
		    overflow: hidden;
		}
	}
}
body #footer-blocks {
	background-color: $blackFooter;
	max-width: 1500px;
    margin: 0 auto !important;
    padding: 1% 2%;
	*,
	a {
		color: $white !important;
		text-decoration: none;
	}

	.collapse a:before {
		content: "\002022";
		color: $red;
		margin-right: 3px;
		// font-size: 2rem;
		&:not([href*=international]) {
			content: '';
			margin-right: 0;
		}
	}
	.collapse a[href*=international] {
		margin-top: 1rem;
		display: inline-block;
		&:before {
			content: none;
		}
		&:after {
			content: '>>>';
		}
	}
	h1,
	h2,
	h3,
	h4,
	h5 {
		font-size: 1rem;
		font-weight: 400;
		text-transform: uppercase;
	}
	

}
#acb-content {
	max-width: 1500px;
	margin: auto;
	padding: 2%;
	p {
		color: #fff;
	}
}

@media (max-width: 767px) {
	body #footer-blocks .collapse {
	    display: none;
	    margin: 0;
	    background-color: transparent;
	}
}