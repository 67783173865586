#expressOrder {
    clip-path: polygon(0% 0%, 100% 0%, 73.4% 100%, 0% 100%);
    background: $red;
    background: radial-gradient(circle, #FC2527 0%, #980a06 100%);
    padding: 2.5rem;
    color: $white;
    height: 100%;

    .title-img {
        text-indent: -999px;
        height: 72px;
        width: 263px;
        float: left;
        background-repeat: no-repeat;
    }

    div.title-block {
        position: relative;

        &>p.tagline {
            font-weight: bold;
            padding: 0;
            font-size: 1rem;
            color: $white !important;
            border: 0;
            text-transform: uppercase;
            bottom: 0;
            left: 17rem;
            margin: 0;
            font-style: italic;
            position: absolute;
            @include respond(tab-phone) {
                position: relative;
                float: none;
                clear: both;
                margin-top: 0.5rem;
                left: 0;
                top: 0.5rem;
            }
        }
    }

    a {
        color: $white !important;
        text-decoration: underline;
    }
    @include respond(tab-port) {
        clip-path: none;
    }
}



.configurator_form {
    margin-top: 2.5rem;

    legend {
        font-weight: bold;
        padding: 0;
        margin-bottom: 15px;
        font-size: 1.25rem;
        color: #FFFFFF;
        border: 0;
        line-height: 1.2;
    }

    label {
        color: $white;
        font-size: 1rem;
        margin: 6% 0;
    }

    input {
        border: 0;
        box-shadow: inset 2px 2px 6px 0 #777777;
        border-radius: 12px;
        font-size: .825rem;
        padding: 2px 6px;
        width: 89px;
        height: 18px
    }
}

button.calc {
    height: 22px;
    color: #FFFFFF;
    text-transform: uppercase;
    padding: 0 10px;
    position: relative;
    border-radius: 12px;
    background-color: #ff0000;
    box-shadow: inset 0 2px 30px 0 #993131;
    margin-left: 20px;
    outline: none;
    font: inherit;
}

.calc:after,
.vf-btn:after {
    content: "";
    width: 90%;
    height: 9px;
    display: block;
    position: absolute;
    left: 5%;
    top: 14%;
    border-radius: 8px;
    background: $gradientRed;
}
#gsize .error {
    border: solid 1px #eee;
    box-shadow: inset 0 0 2px 0 #eee;
    border-radius: 4px;
    color: #eee;
    background: #900;
    font-size: 15px;

}
#gsize-msg {
    padding: 2px 4px;
    margin: 4px 8px;
    display: inline-block;
}
.gsize {
    .explain,
    .howto {
        @include flex(flex-start, center);
        font-size: 0.875rem;
        line-height: 1.4;
    }
    .d-flex {
        margin: 2% 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        @include respond(tab-sm) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .x {
                display: none;
            }
            button.calc {
                position: absolute;
                right: 3rem;
                top: 50%;
                transform: translateY(-50%);
            }
            label:last-of-type {
                margin-top: 1rem;
            }
            input:last-of-type {
                margin-bottom: 1rem;
            }
        }
        @include respond(phone-xs) {
            button.calc {
                position: relative;
                right: auto;
                top: 0;
                transform: none;
            }
        }

    }
    .tagline {
        width: 100%;
    }
    
} 

.special {
    margin-top: 50px;
    font-size: 0.875rem;
    @include flex(flex-start, center);

    img {
        width: 42px;
        margin-right: 10px;
    }

    p {
        font-size: 0.875rem;
        color: $white !important;
        margin: 0;
    }
}

#order-step {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    // padding-bottom: 30px;
    @include respond(tab-land) {
        display: block;
    }
    &.alt {
        background: url(../../img/fireplace.png) top left no-repeat;

        @include respond(order) {
            background: none;
        }
    }

    h3 {
        color: $white;
    }
    .resume {
        ul {
            padding-bottom: 2rem;
        }
    }
}

#engagement,
#order-step {
    position: relative;
    h1 {
        :after {
            content: "";
            width: 30%;
            height: 9px;
            display: block;
            position: absolute;
            left: 38%;
            top: 14%;
            border-radius: 8px;
            background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0, rgba(255, 255, 255, 0.7) 8%, rgba(255, 255, 255, 0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=0);
        }
    }

    .h3 {
        margin-bottom: 1.2rem;
        padding-right: 42px;
        font-size: 19pt;
        text-align: center;
        text-transform: uppercase;
        font-weight: normal;
        color: $red;

    }

    .red {
        color: $red;
        text-transform: uppercase;
        font-size: 0.875rem;
        font-weight: bold;
        text-align: center;
    }

    .black {
        font-size: 0.875rem;
        text-align: center;
    }

    .item {
        @include flex(flex-start, center, column);
        margin-bottom: 1rem;

        img {
            max-width: 5rem;
        }
    }

    &__content {
        position: absolute;
        right: 0;
        top: 2.5rem;
        width: 120%;
        margin: auto;
        @include respond(tab-port) {
            position: relative;
            width: 100%;
            .item.col-sm-2 {
                width: 20%;
            }
        }
        @include respond(phone) {
            .item.col-sm-2 {
                width: 50%;
            }
        }
    }
}

.crumb {
    @include flex(center, center);
    flex-wrap: wrap;
    &>li {
        position: relative;
        margin-right: 1rem;
        &:after {
            position: absolute;
            left: 100%;
            top: 0;
            content: '';
            width: 0;
            height: 0;
            border-top: 14px solid transparent;
            border-left: 9px solid $bg_grey;
            border-bottom: 14px solid transparent;
            z-index: 1;
        }
        &:before {
            position: absolute;
            right: 100%;
            bottom: 0;
            content: '';
            width: 0;
            height: 0;
            border-top: 14px solid #e8e8e8;
            border-bottom: 14px solid #e8e8e8;
            border-left: 11px solid transparent;
            background-color: #ffffff;
        }

    }
    &>li.active {
        &:before {
            border-top-color: $yellow !important;
            border-bottom-color: $yellow !important;
        }
    }
    &>li.active {
        &:after {
            border-left-color: $yellow !important;
        }
    }
    li {
        float: left;
        list-style-type: none;
        background-color: $bg_grey;
        line-height: 28px;
        height: 28px;
        padding: 0 10px;
        position: relative;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 0.7rem;
        box-sizing: border-box;
        &.active {
            color: #ffffff;
            background: $yellow;
        }
    }
}

.resume {
    color: #FFFFFF;
    padding-top: 70px;
    padding-left: 30px;
    background: $red;
    background: radial-gradient(circle, #FC2527 0%, #980a06 100%);

    h3 {
        font-size: 1.3rem;
        text-transform: uppercase;
        margin: 0;
        font-weight: bold;

        &:before {
            content: '> ';
        }

        &.resume h3:after {
            content: ' :';
        }
    }

    p {
        color: $white !important;
    }

    li a {
        color: #FFFFFF;
        border-radius: 6px;
        background-color: #ff0000;
        box-shadow: inset 0 0 15px 2px #993131;
        padding: 0 4px;
        font-size: 13pt;
        font-weight: bold;
        position: relative;

        &:after {
            content: "";
            width: 30%;
            height: 9px;
            display: block;
            position: absolute;
            left: 38%;
            top: 14%;
            border-radius: 8px;
            background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0, rgba(255, 255, 255, 0.7) 8%, rgba(255, 255, 255, 0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=0);
        }

        &:hover {
            text-decoration: none;
            background-color: #e8c207;
            box-shadow: inset 0 0 15px 2px #be9f0a;
        }
    }

}

.configurator {
    padding: 1rem 3rem 4rem !important;

    .cons {
        margin: 5% auto 0;
        @include flex(space-evenly, center);
        @include respond(tab-land) {
            width: 100%;
            .submitter {
                width: 100%;
            }
        }
        @include respond(tab-sm) {
            flex-direction: column;
        }
        p {
            font-size: 12pt;
            font-weight: bold;
            text-transform: uppercase;

            & .important {
                color: $red;
            }
        }
    }

    .price {
        text-align: right;
        color: #b51818;
        font-size: 18pt;
        font-weight: bold;
        margin-bottom: 0.5rem;
    }

    .separator {
        color: $red;
        align-self: flex-start;
        margin-top: 1.5rem;
        font-weight: 900;
        text-transform: uppercase;
        @include respond(tab-land) {
            margin: 1.5rem 1rem;
        }
    }
    .configurator-content {
        @include respond(tab-land) {
            @include flex(flex-start, flex-start, column);
        }
        @include respond(tab-sm) {
            .description {
                order: 2;
                margin-top: 1.5rem;
            }
            .cons {
                order: 1;
            }
        }
    }

}

.configurator .car,
.configurator .cdr,
.payment_module {
    width: 44%;
    float: left;
    border: 2px solid #c32b2a;
    padding: 15px;
}