body .contact-form {
    color: $black;
}
.question-content {
	line-height: 2.5;	
}
.question {
	text-decoration: none;
	transition: all 0.4s;
    @include flex(flex-start, center);
	&:hover {
		color: $red;
	}
    
}
.reponse-content {
    ul {
        color: $black;
        list-style: disc;
        margin-left: 4%;
    }
}
span.more-less {
    display: inline-block;
    background: $black;
    color: $white;
    padding-top: 4px;
    margin-right: 5px;
    height: 22px;
    width: 22px;
    line-height: 1;
    text-align: center;
    vertical-align: top;
}
.question-content-wrapper {
    @include flex(flex-start, center);
}

