#single_brands {
	@include flex(flex-start, center);
	img {
		width: 264px;
		min-width: 125px;
	}
}
body {
	.product-information .manufacturer-logo {
	    max-height: 4rem;
	    width: auto;
	}
	&#manufacturer #main ul {
	    display: block;
	    break-inside: avoid;
	}
} 
.column-brand {
	column-count: 3;
	@include respond(tab-phone) {
		column-count: 2;
	}
	@include respond(phone-xs) {
		column-count: 1;
	}
}