.thumbnail-top {
	img {
		max-width: 124px !important;
	}
}
body {
	.product-miniature .product-price-and-shipping {
	    font-weight: 700;
	    color: $red;
	    text-align: center;
	    font-size: 1.25rem;
	}
}