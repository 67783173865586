// .form-informations, .field-password-policy {
// 	display: none;
// }
body#checkout {
	section.checkout-step.-reachable.-current .step-number {
	    background-color: $red !important;
	}
	#header .header-nav .logo {
	    width: 100% !important;
	    max-height: none !important;
	}
	section.checkout-step .address-item.selected {
	    background: #fff;
	    border: $red 3px solid !important;
	}
} 
body .custom-radio input[type=radio]:checked+span {
    background-color: $red;
}
.payment-option {
	@include flex(flex-start, center);
	img {
		width: auto;
		height: 40px;
	}
}
.order-confirmation-table > table > tbody > tr:nth-child(2) {
	display: none;
}
body.page-my-account #content .links a:hover i {
	color: $red;
}