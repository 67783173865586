body#cms {
	img {
		width: auto;
	}
	iframe {
		width: 100%;
	}
}
.cms-content {
	padding: 2%;
}
.button_mini {
	position: relative;
    display: inline-block;
    padding: 5px 7px;
    border-radius: 3px !important;
    font-weight: bold;
    color: $white !important;
    cursor: pointer;
    background: rgb(200,40,40);
    text-decoration: none;
}