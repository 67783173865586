body #search_filters {
    .facet {
        .facet-label a {
            text-overflow: inherit;
        }
        .facet-title {
            color: $red !important;
            text-shadow: none;
            background: $bg_grey;
            padding: 10px;
            text-transform: uppercase;
            width: 100%;
            margin: 0;
        }
    }
    
    ul {
        @include flex(flex-start, center, column);
        padding: 10px;
        border: 1px solid $black;
    }
}