body {
	color: $black !important;
	line-height: 1.4;
	font-size: 15px !important;
	#wrapper {
		background-color: $white;
	}
	#content-wrapper.col-xs-12 {
		padding-left: 0;
		padding-right: 0;
	}	
	&#index, &#module-estimate-estimate {
		#wrapper {
			padding-top: 0;
		}
	}
	main {
		overflow-x: hidden;
	}	
	h1,
	h2,
	h3,
	h4 {
		font-family: Tahoma, Geneva, sans-serif;
		color: $red;
		margin: 16px 0;
	}

	a {
		color: $black !important;
		text-decoration: underline;
	}

	img {
		width: 100%;
		height: auto;
	}
	@include respond(tab-land) {
		font-size: 14px !important;
	}
	@include respond(tab-port) {
		font-size: 12px !important;
	}
}


button {
	padding: 0;
	margin: 0;
	border: 0;
	font: bold .9375rem Arial, Helvetica, sans-serif !important;
	cursor: pointer;
}

body .container-fluid {
	max-width: 1500px;
	padding-left: 0;
	padding-right: 0;
}

.page-content.page-cms ul,
p {
	font-size: .9375rem;
	font-weight: 400;
	color: $black !important;
}

label {
	max-width: 100%;
	margin-bottom: 5px;
	font-weight: 700;
}