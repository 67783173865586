body #custom-text {
	margin-top: 1.5rem;
	border-top: 2px solid $red;

	table {
		width: 100%;

		tr {
			vertical-align: top;

			@include respond(tab-port) {
				display: flex;
				flex-wrap: wrap;

				td {
					width: 50%;
				}
			}

			td {
				padding: 0 0.8rem;
			}
		}

	}

	h2 {
		color: $red;
		font-size: 1.25rem;
		font-weight: bold;
		margin-bottom: 20px;
		text-transform: uppercase;
	}

	img {
		max-width: 211px;
	}
}