.ets-cfu-form, .faq-content {
	max-width: 1000px;
	margin: 0 auto;
}
.ets_cfu_input_captcha {
	.pa-captcha-img-data {
		max-width: 150px;
	}
	.pa-captcha-refesh {
		max-width: 50px;
		display: inline-block;
		img {
			max-width: 100%;
		}
	}
}