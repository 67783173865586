.bg_grey {
    padding: 2.5rem 0;
    background-color: $bg_grey;
}
.hidden {
    display: none;
}
.radio {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
    &.hidden {
        display: none;
    }
    span {
        @include flex(flex-start, center);
    }
    label {
        margin-bottom: 0;
    }
}
#order-step input[type=text], #order-step input[type=email], #order-step input[type=number], #order-step input[type=password] {
    border: 1px solid $input_grey;
    box-shadow: inset 2px 2px 21px 0 $input_grey;
    border-radius: 12px;
    font-size: .825rem;
    padding: 2px 8px;
    height: 20px;
    color: $black;
    font-weight: normal;
}
.cmd, body #page .standard-checkout, .cart_navigation button, .address_add.submit a, #order-step p.submit input, #order-step button {
    height: 22px;
    color: #FFFFFF;
    text-transform: uppercase;
    position: relative;
    border-radius: 12px !important;
    background-color: #ff0000;
    box-shadow: inset 0 2px 30px 0 #993131;
    float: right;
    text-align: center;
    padding: 0 7px;
    display: block;
    &:hover {
        background-color: #e8c207;
        box-shadow: inset 0 0 15px 2px #be9f0a;
    }
    &:after {
        content: "";
        width: 90%;
        height: 9px;
        display: block;
        position: absolute;
        left: 5%;
        top: 14%;
        border-radius: 8px;
        background: linear-gradient(to bottom,rgba(255,255,255,1) 0,rgba(255,255,255,0.7) 8%,rgba(255,255,255,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff',endColorstr='#00ffffff',GradientType=0 );
    }
}
body .btn-primary {
    background-color: $red;
    color: #fff !important;
    text-decoration: none;
    border-color: transparent;
    outline: 0;
    &:hover, &:focus, &:active {
        background-color: $yellow;
        &:hover {
            background-color: $yellow;
        }
    }
}
body .btn_cms {
    background-color: $red;
    color: #ffffff !important;
    padding: 10px 20px;
    text-decoration: none;
    margin-top: 10px;
    display: inline-block;
    cursor: pointer;
}