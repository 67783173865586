body {
    .header-top-right {
        @include flex(space-between, center, row-reverse);
    }
    #header {
        max-width: 1500px;
        margin: 0 auto;
        box-shadow: none;
        .top-menu {

            &:not(.selected) a[data-depth="0"]:hover,
            a:hover {
                color: $red;
            }

            .selected {
                background-color: $red;

                a {
                    color: $white !important;
                }
            }

            a {
                color: $black !important;
                text-decoration: none !important;
            }
            a[data-depth="0"] {
                padding: 0.625rem;
                font-size: 0.825rem;
                font-weight: 600;
                @include respond(tab-port) {
                    padding: 0.3rem;
                }
                @include respond(tab-port) {
                    font-size: 0.725rem;
                }
            }
        }

        .menu {
            display: block;

            &>ul {
                @include flex(flex-end, center);
            }
        }
        .top-logo {
            img {
                @include respond(tab-sm) {
                    max-height: none;
                }
            }
            
        }
        #search_widget {
            float: none;
            min-width: 100%;
            margin-bottom: 20px;
            @include flex(flex-end, center);
        }

        #_desktop_cart .cart-preview {
            padding: 10px;
            top: 2rem;
            right: 1rem;
            &.inactive {
                border: 2px solid $gray;
            }
            &.active {
                background: $red;
                color: $white !important;
            }
            a {
                color: $white !important;
                font-size: 1rem;
                @include respond(tab-port) {
                    font-size: .8rem;
                }
            }
            i {
                @include respond(tab-port) {
                    font-size: 1.2rem;
                }
            }
            .hidden-sm-down {
                @include respond(tab-apple) {
                    display: none;
                }
            }
        }
        #_mobile_cart {
            a {
                text-decoration: none;

            }
        }
        
        .header-nav {
            .cart-preview.active {
                background: $red;
                .cart-products-count {
                    color: $white;
                }
            }
            #menu-icon {
                margin: 1.2rem 1rem 0;
                .material-icons {
                    font-size: 50px;
                }
            } 
        } 
        .header-top {
            .menu {
                padding-left: 0;
            }
            .container-fluid {
                position: relative;
                width: 100%;
                img.logo {
                    margin-bottom: 20px;
                }
            }
            .row {
                @include flex(space-between, flex-end, column);
                .col-sm-12 {
                    padding-right: 0;
                    padding-left: 0;
                }
                &.hidden-md-up {
                    @include flex(space-between, center, column);
                }
            }
        }
    }

}
.row {
    margin-right: 0 !important;
    margin-left: 0 !important;
}